import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
//element-ui
import Element from 'element-ui'
import "element-ui/lib/theme-chalk/index.css"
//css样式
import "./assets/css/index.css";
import "./assets/css/iconfont.css";
import config from "./assets/js/config";
//axios
import axios from 'axios'
import VueAxios from "vue-axios";
import mavonEditor from "mavon-editor";
import "mavon-editor/dist/css/index.css";
import moment from "moment";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import { generaMenu } from "./assets/js/menu";


Vue.config.productionTip = false
Vue.use(mavonEditor);
Vue.use(VueAxios, axios);
Vue.use(Element)
Vue.prototype.config = config;
Vue.prototype.$moment = moment;

Vue.filter("date", function(value, formatStr = "YYYY-MM-DD") {
  return moment(value).format(formatStr);
});

Vue.filter("dateTime", function(value, formatStr = "YYYY-MM-DD hh:mm:ss") {
  return moment(value).format(formatStr);
});

NProgress.configure({
  easing: "ease", // 动画方式
  speed: 500, // 递增进度条的速度
  showSpinner: false, // 是否显示加载ico
  trickleSpeed: 200, // 自动递增间隔
  minimum: 0.3 // 初始化时的最小百分比
});

router.beforeEach((to, from, next) => {
  NProgress.start();
  if (to.path == "/login") {
    next();
  } else if (!store.state.userId) {
    next({ path: "/login" });
  } else {
    next();
  }
});

router.afterEach(() => {
  NProgress.done();
});

// 响应拦截器
axios.interceptors.response.use(
    function(response) {
      switch (response.data.code) {
        case 40001:
          Vue.prototype.$message({
            type: "error",
            message: response.data.message
          });
          router.push({ path: "/login" });
          break;
        case 50000:
          Vue.prototype.$message({
            type: "error",
            message: response.data.message
          });
          break;
      }
      return response;
    },
    function(error) {
      return Promise.reject(error);
    }
);

new Vue({
  router,
  store,
  render: h => h(App),
  created() {
    // 刷新页面查询用户菜单
    if (store.state.userId != null) {
      generaMenu();
    }
  }
}).$mount('#app')
