import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/login/Login'

Vue.use(VueRouter)

const routes = [
    {
    path: '/login',
    name: 'Login',
    component: Login
  }
]

const createRouter = () =>
    new VueRouter({
        mode: "history",
        routes: routes
    });

const router = createRouter();

export function resetRouter() {
    const newRouter = createRouter();
    router.matcher = newRouter.matcher;
}

export default router
